* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* =================================GLOBALS======================== */
:root {
  --black: #1f1f1f;
  --light-black: #3b3b3b;
  --lighter-black: #595959;
  --light-purple: #f4f8fc;
  --gray: #ccc;
  --medium-gray: #a0a0a0;
  --dark-white: #f5f5f5;
  --dark-gray: #5b5b5b;
  --transparent-black: rgba(0, 0, 0, 0.25);
  --white: #ffffff;
  --blue: #435cb5;
  --deep-blue: #3a7fe6;
  --light-blue: #e9fdff;
  --steel-blue: #1595c9;
  --light-orange: #ffbb00;
  --reddish: #c95858;
  --red: #ff4a4a;
  --light-red: #ff6868;
  --maroon: #770909;
  --light-pink: #f2f1f1;
  --orange: rgb(255, 187, 0);
  --green: #279f2c;
  --pink: #b92474;
  --light-green: rgb(18, 160, 18);
}

html,
body,
#root {
  overflow-x: hidden;
}

#main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1 0 auto;
}

select {
  appearance: auto !important;
  /* height: 45px !important; */
}

.error-box {
  background-color: var(--light-red);
  color: var(--white);
  padding: 0.5rem 1rem;
}

.success-box {
  background-color: var(--light-green);
  color: var(--white);
  padding: 0.5rem 1rem;
}

.position-center {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

/* z-indexes */
.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

/* padding classes */
.padding-65 {
  padding-top: 65px !important;
}
.padding-125 {
  padding-top: 125px !important;
}

/* border colors */
.table-row {
  border: 1px solid var(--gray) !important;
}

.border-blue {
  border: 1px solid var(--blue);
}

.border-light-blue {
  border: 1px solid var(--light-blue);
}

.border-gray {
  border: 1px solid var(--gray) !important;
}
.border-right-gray {
  border-right: 1px solid var(--gray) !important;
}

/* ================ BORDER RADIUS CSS ======================================  */
.br-3 {
  border-radius: 3px !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-12 {
  border-radius: 12px !important;
}
.br-13 {
  border-radius: 13px !important;
}
.br-15 {
  border-radius: 15px !important;
}
.br-20 {
  border-radius: 20px !important;
}
.br-22 {
  border-radius: 22px !important;
}
.br-25 {
  border-radius: 25px !important;
}
.br-50 {
  border-radius: 50px !important;
}

/* borders */
.border-top-white {
  border-top: 1px solid var(--white);
}

/* font sizes */
.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}
@media screen and (max-width: 991px) {
  .font-35 {
    font-size: 21px;
  }
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

/* font weights */
.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-900 {
  font-weight: 900;
}

.icon-50 {
  min-width: 50px;
  min-height: 50px;
  cursor: pointer;
}
.icon-40 {
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
}
.icon-30 {
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
}
.icon-25 {
  min-width: 25px;
  min-height: 25px;
  cursor: pointer;
}
.icon-23 {
  min-width: 23px;
  min-height: 23px;
  cursor: pointer;
}

/* text colors */
.text-light-black {
  color: var(--light-black);
}
.text-medium-gray {
  color: var(--medium-gray) !important;
}
.text-dark-gray {
  color: var(--dark-gray) !important;
}
.text-gray {
  color: #969393 !important;
}

.text-maroon {
  color: var(--maroon);
}
.text-blue {
  color: var(--blue);
}
.text-deep-blue {
  color: var(--deep-blue) !important;
}

.text-orange {
  color: var(--light-orange);
}

.text-sky-blue {
  color: #18cafc;
}
.text-lighter-black {
  color: var(--lighter-black) !important;
}

.text-lowecase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase;
}

/* line heights */

.line-height-1-5 {
  line-height: 1.5rem;
}

.line-height-1-7 {
  line-height: 1.7rem;
}

.line-height-2-0 {
  line-height: 2rem;
}

.line-height-2-5 {
  line-height: 2.5rem;
}

.line-height-3-0 {
  line-height: 3rem;
}

/* letter spacing */
.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-2-5 {
  letter-spacing: 2.5px;
}
/* background colors */

.bg-red {
  background-color: var(--red) !important;
}
.bg-maroon {
  background-color: var(--maroon) !important;
}
.bg-pink {
  background-color: var(--pink) !important;
}
.bg-blue {
  background-color: var(--blue) !important;
}
.bg-deep-blue {
  background-color: var(--deep-blue) !important;
}
.bg-steel-blue {
  background-color: var(--steel-blue) !important;
}
.bg-black {
  background-color: var(--black) !important;
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-dark-white {
  background-color: var(--dark-white) !important;
}

.bg-light-pink {
  background-color: var(--light-pink);
}

.bg-light-purple {
  background-color: var(--light-purple);
}

.bg-lighter-black {
  background-color: var(--lighter-black) !important;
}

/* min heights */
.page-height {
  min-height: calc(100vh - 260px);
  margin-top: 80px;
}

.cus-space {
  height: 20px;
}

.min-h-40 {
  min-height: 40px;
}

.min-height-20 {
  min-height: 20vh;
}

.min-height-30 {
  min-height: 30vh;
}

.min-height-35 {
  min-height: 30vh;
}

.min-height-40 {
  min-height: 40vh;
}

.min-height-50 {
  min-height: 50vh;
}

.min-height-60 {
  min-height: 60vh;
}

.min-height-70 {
  min-height: 70vh;
}

.min-height-80 {
  min-height: 80vh;
}

.min-height-90 {
  min-height: 90vh;
}

.min-height-100 {
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.height-45 {
  height: 45px !important;
}

.height-40 {
  height: 40px !important;
}

.height-35 {
  height: 35px !important;
}

.height-30 {
  height: 30px !important;
}

/* max height */
.max-height-70 {
  max-height: 70vh;
  overflow: auto;
}

.max-height-80 {
  max-height: 80vh;
  overflow: auto;
}

.max-height-90 {
  max-height: 90vh;
  overflow: auto;
}

.max-height-100 {
  max-height: 100vh;
  overflow: auto;
}
/* width */
.min-width-10 {
  min-width: 10px !important;
}

.min-width-15 {
  min-width: 15px !important;
}

.min-width-20 {
  min-width: 20px !important;
}

.min-width-30 {
  min-width: 30px !important;
}

.min-width-35 {
  min-width: 35px !important;
}

.min-width-50 {
  min-width: 50px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.min-width-200 {
  min-width: 200px !important;
}

.min-width-250 {
  min-width: 250px !important;
}

.max-w-450 {
  width: 100%;
  max-width: 28.125rem !important;
}
.max-w-500 {
  width: 100%;
  max-width: 31.25rem !important;
}

.max-w-700 {
  width: 100%;
  max-width: 43.75rem !important;
  margin: 0 auto;
}
.max-w-750 {
  width: 100%;
  max-width: 46.875rem !important;
  margin: 0 auto;
}
.max-w-800 {
  width: 100%;
  max-width: 50rem !important;
  margin: 0 auto;
}
.max-w-900 {
  width: 100%;
  max-width: 56.35rem !important;
  margin: 0 auto;
}
.max-w-950 {
  width: 100%;
  max-width: 59.375rem !important;
  margin: 0 auto;
}
.max-w-1000 {
  width: 100%;
  max-width: 62.5rem !important;
  margin: 0 auto;
}

/* buttons */
.btn {
  padding: 0.5rem 1rem;
  display: inline-flex;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  outline: none;
  border: 1px solid;
  min-width: 200px;
  border-radius: 30px;
  height: 50px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.file-btn,
.btn.btn-blue {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--blue) !important;
}

.file-btn {
  height: 40px;
  padding: 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
}

.btn.btn-outline-blue:hover {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--white) !important;
}

.btn.btn-outline-blue,
.btn.btn-blue:hover {
  background-color: var(--white);
  color: var(--blue);
}

.btn.btn-outline:hover,
.btn.btn-primary {
  background-color: var(--white);
  color: var(--black);
  border-color: var(--white) !important;
}

.btn.btn-outline,
.btn.btn-primary:hover {
  background-color: var(--white);
  color: var(--blue);
}

.btn.btn-secondary {
  background-color: var(--black);
  color: var(--white);
  border-color: var(--black) !important;
}

.btn.btn-secondary:hover {
  background-color: var(--white);
  color: var(--black);
}

.btn.btn-lighter-secondary-outline:hover,
.btn.btn-lighter-secondary {
  background-color: var(--lighter-black);
  color: var(--white);
  border-color: var(--lighter-black) !important;
}

.btn.btn-lighter-secondary-outline,
.btn.btn-lighter-secondary:hover {
  background-color: var(--white);
  color: var(--lighter-black);
}

.btn.btn-warning {
  background-color: var(--light-orange);
  color: var(--black);
  border-color: var(--light-orange) !important;
}

.btn-danger {
  background-color: var(--red);
  color: var(--white);
  border-color: var(--red) !important;
}

.btn-danger:hover {
  background-color: var(--white);
  color: var(--red);
}

.btn-block {
  display: inline-block !important;
}

.upload-file {
  border-bottom: 1px solid var(--blue);
  padding: 0;
  margin: 0;
}

.upload-file-text {
  /* width: 100%; */
  /* display: inline-block; */
  height: 40px;
  padding: 0.5rem 1rem;
}

textarea {
  padding: 0.5rem !important;
  resize: none;
  border: 1px solid var(--gray);
  border-radius: 5px;
  width: 100%;
  height: 130px;
}
.form-group.input-error {
  position: relative;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.form-group .input-error-box {
  position: absolute;
  display: block;
  min-width: 300px;
  width: 100%;
  top: 55px;
  color: var(--red);
}

.saved {
  border: none;
  padding: 0.5rem;
  background: var(--white);
  font-size: 18px;
  color: var(--green);
  align-items: center;
}

.saved .fa {
  font-size: 22px;
  color: var(--green);
}

input[type="radio"] + label {
  cursor: pointer;
}

input[type="radio"]:checked + label:before {
  background-color: var(--blue);
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  top: 4px;
  left: -10px;
  position: relative;
  background-color: var(--gray);
  border-radius: 50%;
}

input[type="radio"] {
  visibility: hidden;
}

.error-page img {
  max-height: 100%;
}

@media screen and (max-width: 768px) {
  .error-page img {
    max-height: 450px;
  }
}

/* ==========================END OF GLOBALS========================== */

/* ==========================HOME PAGE================================ */
/* -------------------------HEADER------------------------------ */
.header {
  position: fixed;
  width: 100%;
  max-height: 85px;
  left: 0px;
  top: 0;
  background: var(--white);
  box-shadow: 0 1px 3px 2px var(--transparent-black);
  z-index: 200;
}

.header .logo a {
  text-decoration: none;
  color: var(--light-black);
  font-weight: 500;
}

.header .header-nav .header-nav-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  position: relative;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;
}

.header .header-nav .header-nav-item:hover {
  background-color: var(--blue);
  color: var(--white);
  border-radius: 5px;
}

.nav-active {
  background-color: var(--blue) !important;
  color: var(--white) !important;
  border-radius: 5px !important;
}

.header .header-navbar-menu {
  display: none;
}

@media screen and (max-width: 1600px) {
  .header .header-nav .header-nav-item {
    margin: 0;
  }
  .header .logo img {
    width: 60px;
  }
  .header .logo .logo-text {
    font-size: 23px;
  }
}
@media screen and (max-width: 1400px) {
  .header .header-nav .header-nav-item {
    font-size: 15px;
    padding: 0.5rem 0.7rem;
  }
}
@media screen and (max-width: 1199px) {
  .header {
    display: block !important;
    padding: 0.5rem 1rem 0.5rem 0.5rem !important;
    max-height: 70vh;
  }

  .header .header-nav {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s linear;
  }

  .header .header-nav.show {
    max-height: 60vh;
  }

  .header .header-nav .header-nav-item,
  .header .header-navbar-menu {
    display: block;
    margin: 0;
  }

  .header .header-nav .header-nav-item.active::after,
  .header .header-nav .header-nav-item:hover::after {
    display: none;
  }

  .header .header-nav .header-nav-item {
    text-align: right;
    padding: 0.8rem 0.5rem;
    transition: all 0.3s ease-in-out;
  }

  .header .header-nav .header-nav-item.active,
  .header .header-nav .header-nav-item:hover {
    background-color: var(--blue);
    color: var(--white);
  }
}

@media screen and (max-width: 768px) {
  .header .header-nav .header-nav-item:hover {
    background-color: none;
    color: var(--black);
    border-radius: none;
  }
  .nav-active {
    background-color: none !important;
    color: var(--black) !important;
    border-radius: none !important;
  }
}

@media screen and (max-width: 420px) {
  .header {
    padding: 0 0.5rem !important;
  }
  .header .logo img {
    height: 70px;
  }

  .header .logo .logo-text {
    font-size: 23px;
  }
}

/* -------------------------END OF HEADER------------------------ */
/* ---------------------------HOME MAIN-------------------- */
.home {
  width: 100vw;
  overflow-x: hidden;
}

.home-main .content h2 {
  line-height: 53px;
}

.home-main .content p {
  line-height: 29px;
}

@media screen and (min-width: 768px) {
  #home-main .content {
    text-align: left !important;
  }
}

@media screen and (min-width: 1440px) {
  .home-main .content {
    width: 80%;
  }
}

/* -------------------------END OF HOME MAIN---------------- */

/* -------------------------HOME HOW IT WORKS-------------------------- */

#home-how-it-works img {
  width: 100%;
  max-width: 395px;
}

.how-it-works-box {
  position: relative;
  border-left: 3px solid var(--blue);
  height: 150px;
  padding-left: 2.5rem;
}

.how-it-works-box-borderless {
  border: none !important;
}

.count-box {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #6d34e4;
  top: 0px;
  left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

#home-how-it-works .action-btn {
  height: 35px;
  background-color: var(--deep-blue);
  border: none;
  color: var(--white);
}

@media screen and (max-width: 600px) {
  .how-it-works-box {
    height: 240px;
  }
}

/* ------------------------END OF HOME HOW IT WORKS----------------------------- */

/* ------------------------ HOME BENEFIT CSS -------------------------------- */

.benefit-card .benefits-count-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefit-card-blue {
  background-color: var(--deep-blue);
  color: var(--white) !important;
}

.benefit-card-blue .benefits-count-box {
  background-color: var(--white);
  color: var(--black);
}

.motors-wrapper {
  border-radius: 10px;
}

@media screen and (max-width: 991px) {
  .benefit-card img {
    width: 100%;
  }

  .motors-wrapper {
    width: 100%;
  }
}

/* -------------------------END OF HOME BENEFIT CSS ---------------------------------- */

/* --------------------------OUR NUMBERS CSS--------------------------------------- */
.number-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.number-box img {
  width: 200px;
}

.number-count-box {
  background-color: var(--reddish);
  color: var(--white);
  padding: 0.4rem 4rem;
  border-radius: 7px;
}

/* -------------------------END OF OUR NUMBERS CSS---------------------------------------- */

/* ====================================RENT VEHICLES==================================== */

.step-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-subheading {
  position: absolute;
  top: 50px;
  min-width: 200px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.desc-tooltip {
  position: relative;
  display: inline-block;
}

/* .desc-tooltip-content {
  position: absolute;
  background-color: var(--white);
  z-index: 10;
  min-width: 400px;
  bottom: 100%;
  visibility: hidden;
  border: 3px solid lightblue;
} */

/* .desc-tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
} */

.desc-tooltip:hover .desc-tooltip-content {
  visibility: visible;
  opacity: 1;
}

.step-one-more-sections {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 4rem;
  margin-top: 50px;
}

.view-list-vehicles,
.on-board-in-bulk {
  border: 0.5px solid #435cb5;
  border-radius: 29px;
  background: #f5f7f9;
  position: relative;
}

.step-one-more-sections .blue-button {
  color: white;
  border-radius: 29px;
  font-size: 17px;
  height: 35px;
  font-weight: 500;
  position: absolute;
  bottom: -17.5px;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: #435cb5;
}

.viewBtn {
  color: white;
  border-radius: 29px;
  font-size: 17px;
  font-weight: 500;
  background-color: #435cb5;
  border: none !important;
  border-radius: 3px;
  height: 42px;
}

.viewBtn-outline {
  color: var(--light-black);
  font-size: 17px;
  font-weight: 500;
  background-color: var(--white);
  border: 1px solid var(--light-black);
  border-radius: 3px;
  height: 42px;
}

.rent-details input {
  font-size: 10px;
}

.step-one-more-sections .blue-button:focus {
  outline: none;
  box-shadow: none;
}
.agreement-wrapper {
  height: 65vh;
  background: var(--white);
  border: 0.3px solid var(--light-black);
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: auto;
  padding: 0;
  position: relative;
}

.agreement {
  margin: 0;
  padding: 1rem;
}

.agreement-wrapper .agree-section {
  height: 130px;
  left: 200px;
  top: 672px;
  background: var(--dark-white);
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1rem;
}

.agreement-wrapper .scroll-section {
  position: sticky !important;
  bottom: 0 !important;
  margin-top: 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.agreement-wrapper .scroll-section .overlay {
  background: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.8),
    rgba(184, 184, 184, 1)
  );
  height: 150px;
  width: 100%;
  position: absolute;
  bottom: 50px;
  display: block;
  z-index: 2;
}

.agreement-wrapper .scroll-section span {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  bottom: 70px;
  z-index: 10;
  background: var(--white);
  color: var(--blue);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.agreement-wrapper .scroll-section span .icon {
  font-size: 40px;
}

.step3 .next-upload-btn {
  background-color: var(--blue);
  border: none;
  color: var(--white);
  height: 40px;
  border-radius: 5px;
}

.uploads-simple-head label {
  background-color: var(--light-gray);
  padding: 0.5rem;
  height: 40px;
  border-radius: 5px;
}

.uploads-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

.video {
  width: 100% !important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  border-radius: 5px;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
}

.cancel-upload {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.map-box {
  height: 60vh;
}

.map-wrapper {
  padding: 0.7rem;
  background: var(--white);
  border: 0.3px solid var(--gray);
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
}

.map-wrapper .location-input {
  position: absolute;
  z-index: 10;
  min-width: 300px;
  left: 1rem;
}

.map-wrapper .location-input input {
  background-color: var(--white);
}

.map-progress {
  position: absolute;
  background-color: var(--white);
  bottom: 15px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 80%;
}

.rent-vehicles .step4 h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
}

.step5 input {
  background-color: var(--light-gray);
}

.next-main-step-upload {
  min-width: 250px;
  background-color: var(--orange);
  border: none;
  border-radius: 5px;
}

.step5 input:focus {
  background-color: #fff;
  outline: none;
  box-shadow: none;
}

.review-submit-btn-wrapper {
  border-radius: 5px;
}

.review-submit-btn {
  border: none;
  border-radius: 5px;
  background-color: var(--orange);
  padding: 0px 0.5rem 0rem 2.5rem;
  font-weight: bold;
  height: 40px;
}

.step6 a {
  text-decoration: none;
}

.review-submit-request-btn {
  background: var(--green);
  border: none;
  border-radius: 5px;
  padding: 0px 2.5rem;
  font-weight: bold;
  height: 40px;
}

.net-earnings-btn {
  /* border: ; */
  height: 23px;
  font-weight: 700;
  background: #5b5b5b;
  border-radius: 46px;
  min-width: 80px;
  padding: 1rem;
  color: var(--white);
  border: 4px solid var(--gray);
}

.agreementStep6 {
  height: 35vh !important;
}

.upload-input-wrapper label {
  background-color: #d4d4d4;
}

@media screen and (min-width: 1440px) {
  .step-one-more-sections {
    column-gap: 2rem;
    margin-top: 100px;
  }
}

@media screen and (max-width: 1140px) {
  .step-one-more-sections {
    column-gap: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .step-one-more-sections {
    grid-template-columns: auto;
  }

  .view-list-vehicles {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .uploads-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
  .video {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .uploads-container {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }

  .video {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .step-one-more-sections .blue-button {
    font-size: 12px;
  }
}

/* ====================================END OF RENT VEHICLES=============================== */

/* =========================================CONTACT US============================= */

.contact-section {
  background-color: var(--blue);
}

.contact-section .title {
  padding-left: 2.5rem !important;
}

.contact-email {
  padding: 0.5rem 1rem !important;
  padding-left: 4rem !important;
}

/* ===================================END OF CONTACT US============================== */

/* -------------------------PLATFORM CSS-------------------------------------------- */
#platforms p {
  width: 60%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  #platforms p {
    width: 100%;
    /* margin: auto; */
  }
}

/* --------------------------------END OF PLATFORM CSS ------------------------------------- */
/* =======================================Employer Pricing  Page CSS Start ================================== */

.table-row {
  border: 1px solid var(--light-pink);
}

.table-row > td {
  border-right: 1px solid var(--light-pink);
  padding: 20px;
  font-size: 15px;
  color: var(--medium-gray);
}

.table-row > td:nth-of-type(even) {
  background: #f0f0f080;
  text-align: center;
}

.table-row:nth-of-type(even) {
  background: rgba(196, 196, 196, 0.2) !important;
}

.pricing-heading {
  font-size: 30px;
  color: var(--static-lead);
}

.table-header-box {
  background: var(--white);
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem 0rem;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  color: #646361;
  border: 10px solid #646361;
}

.table-body {
  border: 0.3px solid var(---medium-gray);
  box-shadow: 0px 4px 4px var(--transparent-black);
  border-radius: 5px;
}

.bg-custom-dark {
  background: var(--static-lead);
}

.bg-custom-dark:hover {
  background: var(--static-lead);
  color: var(--white);
}

.icon-color {
  color: var(--light-orange) !important;
}

@media only screen and (max-width: 640px) {
  .table-row > td {
    min-height: 120px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 15px !important;
  }

  .ftd {
    font-weight: bold;
  }
}

/* =======================================Employer Pricing  Page CSS End ================================== */
/* ----------------------------Footer----------------------------------- */
.footer {
  background: var(--black);
  flex-shrink: 0;
}

.footer .footer-nav-links a {
  display: block;
  margin: 0.5rem;
  margin-left: 1rem;
  color: var(--white);
  text-decoration: none;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}

.footer .footer-nav-links a:hover {
  text-decoration: underline;
}

.footer .btn,
.footer .inc-text,
.footer .contact-icons a {
  display: inline-block;
  margin: 0.5rem;
  margin-left: 1rem;
}

.footer-redirect-btn {
  width: 220px;
}

/* ---------------------------End of Footer-------------------------- */
/* ===========================FAQS================================= */
.faqs-table .icon {
  font-size: 27px;
}

.faqs-table .icon.edit-icon {
  /* background-color: var(--orange); */
  /* color: var(--white); */
  font-size: 27px;
}

.faqs-table .icon.delete-icon {
  color: var(--red);
}

.faq-card {
  border-radius: 10px;
}

.faq-header {
  min-height: 40px;
  background-color: var(--blue);
  color: var(--white);
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faqs-container {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 6rem;
  row-gap: 3rem;
}

.cancel-delete-faq-btn,
.delete-faq-btn {
  padding: 0px 6px !important;
  border: none;
  height: 30px;
  min-width: 100px;
}

.cancel-delete-faq-btn {
  background-color: #246eb9;
  color: var(--white);
}

.delete-faq-btn {
  background-color: #ed474b;
  color: var(--white);
  width: 120px;
}

@media screen and (max-width: 992px) {
  .faqs-container {
    display: grid;
    grid-template-columns: auto;
    /* column-gap: 6rem; */
    row-gap: 3rem;
  }
}
/* ==========================END OF FAQS=============================== */

/* =============================ADMIN STYLED============================== */
/* -------------------------------LOGIN----------------------------------- */
.admin-forgot-password .admin-forgot-password-right,
.admin-forgot-password .admin-forgot-password-left,
.admin-login .admin-login-right,
.admin-login .admin-login-left {
  min-height: 100vh;
  padding: 1rem;
}

.form-wrapper {
  box-shadow: 0 1px 2px 1px var(--transparent-black);
  background-color: var(--white);
  padding: 1rem;
  padding-top: 2rem;
  margin-top: 0.5rem;
  min-height: 70vh;
  margin-bottom: 2rem;
}

.admin-forgot-password .admin-forgot-password-left {
  background: url("./dist/images/forgot-password-animate.svg") center/contain
    no-repeat;
}

.admin-reset-pasword .admin-forgot-password-left {
  background: url("./dist/images/reset-password-animate.svg") center/contain
    no-repeat;
}

.admin-login .admin-login-left .logo {
  height: 100px;
}

.admin-forgot-password .admin-forgot-password-right form,
.admin-login .admin-login-right form {
  min-height: 95vh;
}

.admin-forgot-password .admin-forgot-password-right .title,
.admin-login .admin-login-right .title {
  position: relative;
}

.admin-forgot-password .admin-forgot-password-right .title::after,
.admin-login .admin-login-right .title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  height: 2px;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--blue);
  transition: all 0.3s linear;
}

.admin-forgot-password .admin-forgot-password-right .title::after {
  background-color: var(--white);
}

.admin-forgot-password .admin-forgot-password-right .title:hover::after,
.admin-login .admin-login-right .title:hover::after {
  width: 90%;
}

.form-wrapper .form-group,
.admin-login-right .form-group,
.admin-forgot-password-right .form-group,
.reset-password-right .form-group {
  margin: 0.5rem 0;
  position: relative;
}

.form-wrapper .form-group .fa,
.admin-login-right .form-group .fa,
.admin-forgot-password-right .form-group .fa,
.reset-password-right .form-group .fa {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: gray;
}

.form-wrapper .form-control,
.admin-login-right .form-control,
.admin-forgot-password-right .form-control,
.reset-password-right .form-control {
  width: 100%;
  min-width: 28vw;
  padding: 0.5rem 1rem;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .admin-forgot-password .admin-forgot-password-left,
  .admin-login .admin-login-left {
    min-height: 40vh;
  }

  .admin-forgot-password .admin-forgot-password-right,
  .admin-login .admin-login-right {
    min-height: 60vh;
  }

  .admin-forgot-password .admin-forgot-password-right form,
  .admin-login .admin-login-right form {
    min-height: 55vh;
    width: 100%;
  }

  .admin-forgot-password .admin-forgot-password-right .form-group,
  .admin-login .admin-login-right .form-group {
    max-width: 400px;
    width: 100%;
  }

  .admin-forgot-password .admin-forgot-password-left {
    background: url("./dist/images/forgot-password.jpg") center/contain
      no-repeat;
  }

  .admin-reset-pasword .admin-forgot-password-left {
    background: url("./dist/images/reset-password.svg") center/contain no-repeat;
  }
}

/* ---------------------------END OF LOGIN------------------------- */

/* ---------------------------ADMIN HEADER-------------------------- */

.admin-dashboard .admin-header {
  max-width: 100%;
  width: 100%;
  max-height: 80px;
  position: fixed;
  top: 0;
  background-color: var(--white);
  color: var(--blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1rem;
  box-shadow: 0 1px 3px 2px var(--transparent-black);
  z-index: 200;
}

.admin-header-logo .logo-text {
  color: var(--black);
}

.admin-dashboard .admin-header .admin-header-left h5,
.admin-dashboard .admin-header .admin-header-right p {
  color: var(--blue);
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.admin-dashboard .admin-header .admin-header-left h5 {
  font-size: 20px;
  font-family: serif;
}

.admin-dashboard
  .admin-header
  .admin-header-right
  .header-icons
  .icon-container {
  position: relative;
  display: block;
  margin: 5px 1rem;
}

.admin-dashboard .admin-header .admin-header-right .avatar {
  flex-direction: column;
  margin: 5px 1rem;
}

.admin-dashboard .admin-header .admin-header-right .avatar .avatar-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid var(--blue);
  background-color: var(--white);
  cursor: pointer;
}

.admin-dashboard .admin-header .admin-header-right .fa {
  font-size: 25px;
  color: var(--blue);
  cursor: pointer;
}

.admin-dashboard .admin-header .form-control:focus {
  border-color: var(--gray);
  box-shadow: none;
}

.admin-dashboard .admin-header .search {
  border-radius: 30px;
}

.admin-dashboard .admin-header .fa-search {
  background-color: var(--blue);
  color: var(--white);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 45px;
  width: 45px;
  font-size: 17px;
}

.admin-dashboard .admin-header .admin-header-right .header-icons .text-counter {
  position: absolute;
  top: -0.5rem;
  right: -0.7rem;
  color: var(--white);
  background-color: var(--success);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  animation: 1s ease 0s normal none infinite running wobble;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 18%);
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}

/* ------------------------END OF ADMIN HEADER---------------------- */
/* -----------------------------ADMIN SECTIONS------------------------ */
.admin-sections {
  min-height: calc(100vh - 80px);
  display: flex;
  background-color: var(--white);
}

.admin-dashboard .main-content-wrapper {
  flex: 1;
  margin-top: 80px;
  max-height: calc(100vh - 80px);
  overflow: auto;
}

.admin-sidebar {
  background-color: var(--blue);
  width: 100%;
  max-width: 250px;
  height: calc(100vh - 80px);
  margin-top: 80px;
  top: 80px;
  position: sticky;
  padding: 2rem 0;
  overflow-y: auto;
}

.admin-sidebar {
  z-index: 5;
}

.admin-sidebar .sidebar-link .icon .icon-item,
.admin-sidebar .sidebar-item-content {
  color: var(--white);
  transition: all 0.25s linear;
}

.admin-sidebar .sidebar-link .icon .icon-item {
  fill: var(--white);
  transition: all 0.25s linear;
  font-size: 1.5rem;
}

.admin-sidebar .sidebar-link {
  text-decoration: none;
  padding: 0.5rem;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0.5rem;
  border-radius: 5px;
  transition: all 0.25s linear;
}

.admin-sidebar .sidebar-link:first-child {
  margin-top: 0;
}

.admin-sidebar .sidebar-accordion .sidebar-link .accordion-arrow {
  width: 12px;
  height: 12px;
  border-top: 2px solid var(--white);
  border-right: 2px solid var(--white);
  transform: rotate(135deg);
  margin-top: -10px;
  transition: all 0.25s linear;
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body {
  background-color: var(--white);
  padding: 0;
  margin: 0.5rem;
  margin-top: 0rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.25s linear;
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body.open {
  max-height: 50vh;
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body .sidebar-link {
  background-color: var(--white);
  border: 1px solid var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1rem;
}

.admin-sidebar .sidebar-accordion-body .sidebar-link:first-child {
  margin-top: 1rem;
}

.admin-sidebar .sidebar-accordion-body .sidebar-link:last-child {
  margin-bottom: 1rem;
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body .icon-item,
.admin-sidebar .sidebar-accordion-body .sidebar-item-content {
  color: var(--blue);
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body .sidebar-link.active,
.admin-sidebar .sidebar-accordion .sidebar-accordion-body .sidebar-link:hover {
  background-color: var(--blue);
}

.admin-sidebar .sidebar-accordion .sidebar-link.active .accordion-arrow {
  border-top: 2px solid var(--blue);
  border-right: 2px solid var(--blue);
}

.admin-sidebar
  .sidebar-accordion-body
  .sidebar-link.active
  .sidebar-item-content,
.admin-sidebar
  .sidebar-accordion-body
  .sidebar-link:hover
  .sidebar-item-content {
  color: var(--white);
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body .icon-item {
  font-size: 1.5rem;
}

.admin-sidebar .sidebar-accordion .sidebar-accordion-body .sidebar-link .icon {
  font-size: 1.5rem;
  display: none;
}

.admin-sidebar .sidebar-link .icon {
  width: 30px;
  margin-right: 0.7rem;
}

.admin-sidebar .sidebar-link.active,
.admin-sidebar .sidebar-link:hover {
  background-color: var(--white);
}

.admin-sidebar .sidebar-link:hover,
.admin-sidebar .sidebar-accordion .sidebar-link:hover .accordion-arrow {
  border-color: var(--blue);
}

.admin-sidebar .sidebar-link.active .sidebar-item-content,
.admin-sidebar .sidebar-link:hover .sidebar-item-content {
  color: var(--blue);
}

.admin-sidebar .sidebar-link.active .icon .icon-item,
.admin-sidebar .sidebar-link:hover .icon .icon-item {
  fill: var(--blue);
  color: var(--blue);
}

/* ----------------------END OF ADMIN SECTIONS--------------------------- */
/* ----------------------------ADMIN DASHBOARD--------------------------- */
.admins-page,
.admin-faqs {
  padding: 1rem;
}

.main-dashboard {
  background-color: var(--dark-white);
  min-height: calc(100vh - 85px);
}

.main-dashboard .dashboard-component {
  max-width: 356px;
  height: 201px;
  background: var(--white);
  box-shadow: 0px 1px 4px var(--transparent-black);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 2rem;
  cursor: pointer;
}

.main-dashboard .dashboard-component h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 35px;
}

.main-dashboard .dashboard-component h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 76px;
}

.main-dashboard .dashboard-component.applications {
  color: var(--light-black);
}

.main-dashboard .dashboard-component.inquiries {
  color: var(--reddish);
}

/* --------------------------------APPLICATIONS--------------------------- */
.applications .input-group-prepend {
  border: 1px solid var(--gray);
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
}

.applications .input-group-prepend .fa-search {
  font-size: 20px;
  color: var(--blue);
}

.applications .search {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  min-width: 250px;
}

.applications .icon {
  font-size: 35px;
  margin: 0.5rem;
  color: var(--light-black);
  cursor: pointer;
}

.applications .icon.download-icon {
  font-size: 45px;
}

.applications .table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.applications .table th,
.applications .table td,
.applications .table tr {
  border: none;
  margin: 0;
  padding: 0.8rem 0;
}

.applications .table thead th {
  white-space: nowrap;
}

.applications .table .application-top {
  color: var(--white);
  padding: 0.8rem 0.5rem !important;
  border-right: 1px solid var(--gray) !important;
  text-align: center;
}

.applications .table-responsive {
  border: 1px solid var(--gray) !important;
}

.applications .applications-table-row:hover .applications-col-white,
.applications .applications-table-row:hover .applications-col-light-blue,
.applications .applications-table-row:hover {
  background-color: var(--gray);
}

.applications .applications-col {
  white-space: nowrap;
  padding: 0.8rem !important;
  border-right: 1px solid var(--gray) !important;
  border-bottom: 1px solid var(--gray) !important;
  text-align: center;
}

.applications .col-white {
  background-color: #fffde8;
}

.applications .col-light-blue {
  background-color: #ebe6ff;
}
.applications .col-medium-blue {
  background-color: #ddeeff;
}

.applications .btn {
  height: 40px;
  min-width: 150px !important;
  font-size: 16px;
  font-weight: 500;
}

.applications .view-message-container,
.applications .more-info-table {
  position: absolute !important;
  width: 100%;
  background-color: var(--white);
  top: -10rem;
  right: 3rem;
  z-index: 10;
  max-width: 700px;
  padding: 1rem;
  margin-bottom: 0;
  width: 700px;
  display: none;
}

.applications .more-info-table table {
  margin-bottom: 0;
  padding-bottom: 0;
}

.applications .view-message-container {
  min-height: 15vh;
  border: 3px solid #246eb9;
  border-radius: 10px;
  max-width: 500px;
  width: 500px;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
  padding-right: 2.3rem;
  top: -6rem;
}

.applications .view-more-info-table {
  padding: 0.5rem 1rem;
  min-height: 40px;
}

/* .applications .view-more-info-table:hover + .more-info-table  */
.applications .view-message-container.show,
.applications .more-info-table.show {
  display: block;
}

.applications .view-message-container .close-icon,
.applications .more-info-table .close-icon {
  position: absolute;
  top: 0;
  right: 0rem;
  background-color: var(--black);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.applications .view-message-container .close-icon {
  top: -0.8rem;
  right: -2rem;
}

/* -------------------------------END OF APPLICATIONS---------------------- */

/* --------------------------END OF ADMIN DASHBOARD-------------------------- */
/* =========================END OF ADMIN STYLES======================== */
/* ------------------------APP SCREENSHOTS------------------------------- */

#app-screenshots {
  margin-top: 130px;
}

#app-screenshots h4 {
  width: 377px;
  text-align: left;
}

.odd-screenshots {
  color: #797979;
  padding: 2rem 0;
}

.even-screenshot-lg {
  display: block;
  position: relative;
  height: 600px;
}

.even-screenshot {
  display: none;
}

.inner-screenshot-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  color: var(--white);
}

.section-four {
  margin-bottom: -20px !important;
}

@media screen and (min-width: 992px) {
  #app-screenshots img {
    height: 550px;
  }
}

@media screen and (min-width: 1200px) {
  #app-screenshots img {
    height: 600px;
  }

  .even-screenshot-lg {
    display: block;
    position: relative;
    height: 620px;
  }
}

@media screen and (min-width: 1490px) {
  #app-screenshots img {
    height: 610px;
  }
}

@media screen and (min-width: 1500px) {
  #app-screenshots img {
    height: 900px;
  }

  .even-screenshot-lg {
    display: block;
    position: relative;
    height: 910px;
  }
}

@media screen and (max-width: 992px) {
  .even-screenshot-lg {
    display: none;
  }

  #app-screenshots img {
    width: 90%;
    height: auto;
  }

  .even-screenshot {
    color: var(--white);
    padding: 2rem 0;
    display: block;
    background-color: var(--blue);
  }

  #app-screenshots h4 {
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
}

/* ---------------------------END OF APP SCREENSHOTS-------------------------- */

/* ---------------------------CURRENCY CHANGER ----------------------------------- */

.currency-change {
  height: 50px;
  border: 1px solid var(--black);
  width: 200px;
  border-radius: 30px;
  padding: 5px;
}

.currency-change span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-weight: bold;
}

.curreny-active {
  background-color: var(--black);
  color: var(--white);
  border-radius: 30px;
}

/* -----------------------------END OF CURRENCY CHANGER--------------------------------------- */

/* -----------------------------TECHNOLOGY--------------------------------- */
.tech-box {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3rem;
}
.tech-card {
  color: var(--dark-gray) !important;
  position: relative;
  padding-top: 40px;
  text-align: center;
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #a1c2e3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.tech-icon {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: -25px;
}

@media screen and (max-width: 991px) {
  #technologies .tech-text {
    width: 100% !important;
    text-align: left !important;
  }

  .tech-box {
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
  }
}
/* -----------------------------END OF TECHNOLOGY--------------------------------- */

/* -----------------------------BULK APPLICATIONS---------------------------------- */

.bulk-applications-wrapper .table-wrapper {
  /* width: 100vw; */
  overflow: auto;
  margin-top: 90px;
}

.bulk-table-header {
  width: 80.75%;
  z-index: 100;
}

.bulk-table-header .input-group {
  border: 1px solid #ccc;
  border-radius: 29px;
  overflow: hidden;
  height: 40px;
}

.bulk-table-header .bulk-search-input {
  height: 100%;
  border: none;
}

.bulk-table-header .bulk-search-input:focus {
  box-shadow: none;
  outline: none;
}

.bulk-table {
  background-color: #fffde8;
}

.blue-header {
  background-color: #246eb9 !important;
  color: var(--white);
}

.red-header {
  background-color: #ed474b !important;
  color: var(--white);
}

.light-purple-column {
  background-color: #ebe5ff !important;
}

.message-col {
  color: #246eb9;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.message-col-tooltip {
  border: 3px solid #246eb9;
  visibility: hidden;
  background-color: #fff;
  color: #5b5b5b;
  border-radius: 5px;
  position: absolute;
  right: 0;
  z-index: 201 !important;
  opacity: 0;
  transition: opacity 0.3s;
  width: 620.5px;
  bottom: 90%;
}

.message-col-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 90%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #246eb9 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.message-col:hover .message-col-tooltip {
  visibility: visible;
  opacity: 1;
}

.bulk-hash {
  font-size: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

/* -------------------------------END OF BULK APPLICATIONS------------------------------------- */

/* ---------------------------------LOADING---------------------------- */
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(80vh);
}

/* --------------------------------END OF LOADING-------------------------- */

/* ---------------------Vehicle Change----------------------------------- */

.vehicle-change {
  height: 50px;
  border: 1px solid var(--black);
  width: 300px;
  border-radius: 30px;
  padding: 5px;
}

.vehicle-change span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-weight: bold;
}

.vehicle-active {
  background-color: var(--black);
  color: var(--white);
  border-radius: 30px;
}

/* ---------------------------End of vehicle change-------------------------------- */
